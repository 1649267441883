export const environment = {
  production: false,
  config: {
    serverUrl: 'https://caregap-inequities-demo.zsservices.com',
    loginUrl:
      'https://idm.zsservices.com/RWDI/IdentityManager/app/Web/login.aspx?ReturnUrl=',
    logoutUrl:
      'https://idm.zsservices.com/RWDI/IdentityManager/app/Web/logout.aspx',
    idmAccountUrl:
      'https://idm.zsservices.com/RWDI/IdentityManager/app/Web/MyAccount.aspx',
    env: 'demo',
    showCareGapDropdown: true,
  },
};
